import { LegalInfoRequiredFieldsPerPayment, OrderInformation, TextResourceList } from "@/entities";
import { currency, getCookie, loading, setCookie, translation } from "@/utils/common";
import { CountryCode, OrderStatus } from "@/enums";
import { ModalComponent, PayInXComponent } from "@/components";
import { getTextResources } from "tests/entities";
import { get, post } from "@/utils/httpRequest";
import { Telemetry } from "@/utils/telemetry";
import { defineComponent } from "vue";

export default defineComponent({
    name: "PaymentMethodsView",
    components: {
        ModalComponent,
        PayInXComponent,
    },
    props: {
        language: {
            type: String,
            required: true,
        },
        orderKey: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            data: new OrderInformation(),
            textResourceList: new TextResourceList(),
            showErrorPaymentMessage: false,
        };
    },
    beforeCreate() {
        const token: string = this.$route.query.token as string;

        const updatedQueryParams = { ...this.$route.query };
        delete updatedQueryParams.token;
        this.$router.replace({ query: updatedQueryParams });

        if (token) {
            setCookie("token", encodeURIComponent(token));
        } else {
            const existingToken = getCookie("token");
            if (!existingToken) {
                window.location.href = "/error";
            }
        }
    },
    mounted() {
        Telemetry.pageLoaded(`${this.$options.name}`, this.orderKey);
        this.getData();
    },
    methods: {
        async getData() {
            loading();
            try {
                this.textResourceList = (await this.getTextResources(this.language)) as TextResourceList;
                const resp = await get(`authorize-payment/order/${this.orderKey}`);

                if (resp.status != 200) {
                    this.redirectTo(this.data.redirectUrl);
                } else {
                    this.data = resp;
                }

                if (this.data.orderDetails.orderStatus != OrderStatus[OrderStatus.New]) {
                    this.redirectTo(this.data.redirectUrl);
                }
            } catch (e) {
                if (this.data.redirectUrl) {
                    this.redirectTo(this.data.redirectUrl);
                } else {
                    this.showErrorPaymentMessage = true;
                    window.location.href = "#modal-content";
                    document.body.style.overflow = "hidden";
                }
            } finally {
                loading();
            }
        },
        async getTextResources(languageCode: string, fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null): Promise<TextResourceList | getTextResources> {
            const sysnameList = [
                "AccordionTitle",
                "CheckoutTitle",
                "PaymentTitle",
                "CancelPayment",
                "TitleCancelation",
                "DescriptionCancelation",
                "ConfirmCancelation",
                "ContinueWith",
                "PaymentCustomErrorTitle",
                "PaymentCustomErrorSubTitle",
                "ReturnTo",
                "ShippingAddress",
                "BillingAddress",
                "SelectPaymentMethod",
                "OrderDetailsTitle",
            ];

            const response = await post(
                "text-resources/list",
                {
                    languageCode,
                    sysnameList,
                },
                fetchFromTest,
                httpsAgent
            );

            if (!fetchFromTest) return response;

            return { obj: response, list: sysnameList };
        },
        getTranslation(sysName: string, replace: string[] = []): string {
            return translation(this.textResourceList.textResourceItems, sysName, replace);
        },
        currency(value: number) {
            switch (this.data.orderDetails.country) {
                case CountryCode[CountryCode.CH]:
                    return currency(value, "CHF");
                case CountryCode[CountryCode.NO]:
                    return currency(value, "NOK");
                case CountryCode[CountryCode.DK]:
                    return currency(value, "DKK");
                case CountryCode[CountryCode.SE]:
                    return currency(value, "SEK");
                default:
                    return currency(value);
            }
        },
        showAddresses() {
            switch (this.data.orderDetails.country) {
                case CountryCode[CountryCode.SE]:
                case CountryCode[CountryCode.NO]:
                case CountryCode[CountryCode.DK]:
                case CountryCode[CountryCode.FI]:
                    return false;
                default:
                    return true;
            }
        },
        redirectTo(url: string) {
            Telemetry.pageRedirected(`${this.$options.name}`, this.orderKey);
            url = url?.replace(/^https?:\/\//, "");
            window.location.replace("//" + url);
        },
        handleCancel() {
            this.showErrorPaymentMessage = false;
            document.body.style.overflow = "hidden";
        },
        async handleConfirmCancelation() {
            loading();
            const resp = await get(`authorize-payment/cancel-authorize/${this.orderKey}`);
            this.redirectTo(resp.urlRedirect);
        },
        handleContinueWith() {
            window.location.href = "#";
            setTimeout(() => {
                this.showErrorPaymentMessage = false;
            }, 200);
        },
        onChecked(item: LegalInfoRequiredFieldsPerPayment) {
            this.selectPayment(item);
        },
        async selectPayment(item: LegalInfoRequiredFieldsPerPayment) {
            loading();
            const body = {
                type: item.type,
                legalInfo: item.legalInfo,
                requiredFields: item.requiredFields,
                isDirectDebit: item.isDirectDebit,
            };
            const resp = await post(`authorize-payment/select-payment/${this.orderKey}`, body);
            loading();
            if (resp.status != 200 && !resp.isScaRequired) {
                this.showErrorPaymentMessage = true;
                window.location.href = "#modal-content";
                document.body.style.overflow = "hidden";
            } else {
                this.redirectTo(resp.redirectUrl);
            }
        },
    },
});
