import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../../public/flags/at.svg'
import _imports_1 from '../../../public/flags/be.svg'
import _imports_2 from '../../../public/flags/de.svg'
import _imports_3 from '../../../public/flags/nl.svg'


const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row pb-5" }
const _hoisted_4 = { class: "col-12 d-flex" }
const _hoisted_5 = { class: "r-font-family-heading r-font-size-300 r-font-weight-light ps-3" }
const _hoisted_6 = ["hidden"]
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { class: "custom-alert d-flex justify-content-start align-items-center ps-3" }
const _hoisted_9 = { class: "pe-2" }
const _hoisted_10 = ["hidden"]
const _hoisted_11 = { class: "col-12" }
const _hoisted_12 = { class: "r-font-family-heading r-font-size-700 r-font-weight-light pb-3" }
const _hoisted_13 = { class: "r-font-size-400" }
const _hoisted_14 = { class: "row pb-4" }
const _hoisted_15 = { class: "col-12 col-lg-6 pb-4 pb-lg-0" }
const _hoisted_16 = { class: "pb-2" }
const _hoisted_17 = { class: "r-font-size-200 w-100" }
const _hoisted_18 = ["value", "onfocus", "oninput"]
const _hoisted_19 = { class: "col-12 col-lg-6" }
const _hoisted_20 = { class: "pb-2" }
const _hoisted_21 = { class: "r-font-size-200 w-100" }
const _hoisted_22 = ["value", "onfocus", "oninput"]
const _hoisted_23 = { class: "row pb-4" }
const _hoisted_24 = { class: "col-12 col-lg-6 pb-4 pb-lg-0" }
const _hoisted_25 = { class: "pb-2" }
const _hoisted_26 = { class: "r-font-size-200 w-100" }
const _hoisted_27 = ["value", "onfocus", "oninput"]
const _hoisted_28 = { class: "col-12 col-lg-6" }
const _hoisted_29 = { class: "pb-2" }
const _hoisted_30 = { class: "r-font-size-200 w-100" }
const _hoisted_31 = ["value", "onfocus", "oninput"]
const _hoisted_32 = { class: "row py-4" }
const _hoisted_33 = { class: "col-12 col-lg-6" }
const _hoisted_34 = { class: "checkbox" }
const _hoisted_35 = ["checked"]
const _hoisted_36 = { class: "hint" }
const _hoisted_37 = { class: "row pb-5" }
const _hoisted_38 = { class: "col-12 col-lg-6 pb-4 pb-lg-0" }
const _hoisted_39 = { class: "mb-2" }
const _hoisted_40 = { class: "r-font-size-200 w-100" }
const _hoisted_41 = ["value", "disabled"]
const _hoisted_42 = { class: "col-12 col-lg-6" }
const _hoisted_43 = { class: "mb-2" }
const _hoisted_44 = { class: "r-font-size-200 w-100" }
const _hoisted_45 = ["value", "disabled"]
const _hoisted_46 = { class: "row" }
const _hoisted_47 = { class: "col-12 pb-4" }
const _hoisted_48 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hint = _resolveComponent("hint")!
  const _component_r_button = _resolveComponent("r-button")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[1] || (_cache[1] = _createElementVNode("img", {
            alt: "Riverty logo",
            class: "logo",
            src: "https://cdn.riverty.design/logo/riverty-logo-green.svg"
          }, null, -1)),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.getTranslation("SettingsHeader")), 1)
        ])
      ]),
      _createElementVNode("div", {
        class: "row pb-5 pt-4",
        hidden: !_ctx.showMessage
      }, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("strong", _hoisted_9, _toDisplayString(_ctx.getTranslation("SettingsSuccessMessage")), 1),
            _cache[2] || (_cache[2] = _createElementVNode("svg", {
              width: "15",
              height: "12",
              viewBox: "0 0 15 12",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                d: "M14.0601 1.81705L5.19928 11.8661L3.7142 10.1819L12.575 0.132812L14.0601 1.81705Z",
                fill: "#527A42"
              }),
              _createElementVNode("path", {
                d: "M5.19165 11.8631L0 5.97524L1.48508 4.29101L6.67673 10.1789L5.19165 11.8631Z",
                fill: "#527A42"
              })
            ], -1))
          ])
        ])
      ], 8, _hoisted_6),
      _createElementVNode("div", {
        class: "row pb-5",
        hidden: _ctx.showMessage
      }, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.getTranslation("SettingsTitle")), 1),
          _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.getTranslation("SettingsDescription")), 1)
        ])
      ], 8, _hoisted_10),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("span", _hoisted_16, [
            _cache[3] || (_cache[3] = _createElementVNode("img", {
              src: _imports_0,
              class: "pe-1 pb-1"
            }, null, -1)),
            _createTextVNode(_toDisplayString(_ctx.getTranslation("SettingsInputTitle")), 1)
          ]),
          _createElementVNode("label", _hoisted_17, [
            _createElementVNode("input", {
              class: _normalizeClass(["r-input", { check: !_ctx.hasChanded(_ctx.countryCode.AT) && _ctx.getApiKey(_ctx.countryCode.AT).apiKey.length > 0, 'r-input-error': _ctx.hasError(_ctx.errorFieldEnum.NL) }]),
              placeholder: "e.g. 050C812VAB3445546BBD167",
              inputmode: "text",
              value: _ctx.getApiKey(_ctx.countryCode.AT).apiKey,
              onfocus: () => _ctx.handleOnFocus(_ctx.countryCode.AT),
              oninput: (event) => _ctx.handleOnInput(_ctx.countryCode.AT, event)
            }, null, 10, _hoisted_18),
            _withDirectives(_createVNode(_component_hint, { class: "hint" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTranslation("SettingsAustriaHint")), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, !_ctx.hasError(_ctx.errorFieldEnum.AT)]
            ]),
            _withDirectives(_createVNode(_component_hint, { class: "hint-error" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTranslation("SettingsAustriaHintError")), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, _ctx.hasError(_ctx.errorFieldEnum.AT)]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("span", _hoisted_20, [
            _cache[4] || (_cache[4] = _createElementVNode("img", {
              src: _imports_1,
              class: "pe-1 pb-1"
            }, null, -1)),
            _createTextVNode(_toDisplayString(_ctx.getTranslation("SettingsInputTitle")), 1)
          ]),
          _createElementVNode("label", _hoisted_21, [
            _createElementVNode("input", {
              class: _normalizeClass(["r-input", { check: !_ctx.hasChanded(_ctx.countryCode.BE) && _ctx.getApiKey(_ctx.countryCode.BE).apiKey.length > 0, 'r-input-error': _ctx.hasError(_ctx.errorFieldEnum.NL) }]),
              placeholder: "e.g. 050C812VAB3445546BBD167",
              inputmode: "text",
              value: _ctx.getApiKey(_ctx.countryCode.BE).apiKey,
              onfocus: () => _ctx.handleOnFocus(_ctx.countryCode.BE),
              oninput: (event) => _ctx.handleOnInput(_ctx.countryCode.BE, event)
            }, null, 10, _hoisted_22),
            _withDirectives(_createVNode(_component_hint, { class: "hint" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTranslation("SettingsBelgiumHint")), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, !_ctx.hasError(_ctx.errorFieldEnum.BE)]
            ]),
            _withDirectives(_createVNode(_component_hint, { class: "hint-error" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTranslation("SettingsBelgiumHintError")), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, _ctx.hasError(_ctx.errorFieldEnum.BE)]
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("span", _hoisted_25, [
            _cache[5] || (_cache[5] = _createElementVNode("img", {
              src: _imports_2,
              class: "pe-1 pb-1"
            }, null, -1)),
            _createTextVNode(_toDisplayString(_ctx.getTranslation("SettingsInputTitle")), 1)
          ]),
          _createElementVNode("label", _hoisted_26, [
            _createElementVNode("input", {
              class: _normalizeClass(["r-input", { check: !_ctx.hasChanded(_ctx.countryCode.DE) && _ctx.getApiKey(_ctx.countryCode.DE).apiKey.length > 0, 'r-input-error': _ctx.hasError(_ctx.errorFieldEnum.NL) }]),
              placeholder: "e.g. 050C812VAB3445546BBD167",
              inputmode: "text",
              value: _ctx.getApiKey(_ctx.countryCode.DE).apiKey,
              onfocus: () => _ctx.handleOnFocus(_ctx.countryCode.DE),
              oninput: (event) => _ctx.handleOnInput(_ctx.countryCode.DE, event)
            }, null, 10, _hoisted_27),
            _withDirectives(_createVNode(_component_hint, { class: "hint" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTranslation("SettingsGermanHint")), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, !_ctx.hasError(_ctx.errorFieldEnum.DE)]
            ]),
            _withDirectives(_createVNode(_component_hint, { class: "hint-error" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTranslation("SettingsGermanHintError")), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, _ctx.hasError(_ctx.errorFieldEnum.DE)]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("span", _hoisted_29, [
            _cache[6] || (_cache[6] = _createElementVNode("img", {
              src: _imports_3,
              class: "pe-1 pb-1"
            }, null, -1)),
            _createTextVNode(_toDisplayString(_ctx.getTranslation("SettingsInputTitle")), 1)
          ]),
          _createElementVNode("label", _hoisted_30, [
            _createElementVNode("input", {
              class: _normalizeClass(["r-input", { check: !_ctx.hasChanded(_ctx.countryCode.NL) && _ctx.getApiKey(_ctx.countryCode.NL).apiKey.length > 0, 'r-input-error': _ctx.hasError(_ctx.errorFieldEnum.NL) }]),
              placeholder: "e.g. 050C812VAB3445546BBD167",
              inputmode: "text",
              value: _ctx.getApiKey(_ctx.countryCode.NL).apiKey,
              onfocus: () => _ctx.handleOnFocus(_ctx.countryCode.NL),
              oninput: (event) => _ctx.handleOnInput(_ctx.countryCode.NL, event)
            }, null, 10, _hoisted_31),
            _withDirectives(_createVNode(_component_hint, { class: "hint" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTranslation("SettingsNetherlandsHint")), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, !_ctx.hasError(_ctx.errorFieldEnum.NL)]
            ]),
            _withDirectives(_createVNode(_component_hint, { class: "hint-error" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTranslation("SettingsNetherlandsHintError")), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, _ctx.hasError(_ctx.errorFieldEnum.NL)]
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_32, [
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("label", _hoisted_34, [
            _createElementVNode("input", {
              class: "checkbox-input",
              type: "checkbox",
              checked: _ctx.merchantSettings.enableMinMaxAmountFilter,
              onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCheckboxChange && _ctx.handleCheckboxChange(...args)))
            }, null, 40, _hoisted_35),
            _createTextVNode(" " + _toDisplayString(_ctx.getTranslation("SettingsMinMaxAmountFilterTitle")), 1)
          ]),
          _createElementVNode("p", _hoisted_36, _toDisplayString(_ctx.getTranslation("SettingsMinMaxAmountFilterHint")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_37, [
        _createElementVNode("div", _hoisted_38, [
          _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.getTranslation("SettingsMinAmountFilterLabel")), 1),
          _createElementVNode("label", _hoisted_40, [
            _withDirectives(_createElementVNode("input", {
              class: _normalizeClass(["r-input", {
                                'r-input-error': _ctx.hasError(_ctx.errorFieldEnum.MinFilter),
                            }]),
              inputmode: "numeric",
              placeholder: "150,00",
              "data-maska": "9 99#,##",
              "data-maska-tokens": "9:[0-9]:repeated",
              "data-maska-reversed": "",
              value: _ctx.merchantSettings.minAmountFilter,
              disabled: !_ctx.merchantSettings.enableMinMaxAmountFilter
            }, null, 10, _hoisted_41), [
              [_directive_maska, void 0, _ctx.minAmountFilterMaskaOptions]
            ]),
            _withDirectives(_createVNode(_component_hint, { class: "hint" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTranslation("SettingsMinAmountFilterHint")), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, !_ctx.hasError(_ctx.errorFieldEnum.MinFilter)]
            ]),
            _withDirectives(_createVNode(_component_hint, { class: "hint-error" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTranslation("SettingsMinAmountFilterHintError")), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, _ctx.hasError(_ctx.errorFieldEnum.MinFilter)]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_42, [
          _createElementVNode("span", _hoisted_43, _toDisplayString(_ctx.getTranslation("SettingsMaxAmountFilterLabel")), 1),
          _createElementVNode("label", _hoisted_44, [
            _withDirectives(_createElementVNode("input", {
              class: _normalizeClass(["r-input", {
                                'r-input-error': _ctx.hasError(_ctx.errorFieldEnum.MaxFilter),
                            }]),
              inputmode: "numeric",
              placeholder: "300,00",
              "data-maska": "9 99#,##",
              "data-maska-tokens": "9:[0-9]:repeated",
              "data-maska-reversed": "",
              value: _ctx.merchantSettings.maxAmountFilter,
              disabled: !_ctx.merchantSettings.enableMinMaxAmountFilter
            }, null, 10, _hoisted_45), [
              [_directive_maska, void 0, _ctx.maxAmountFilterMaskaOptions]
            ]),
            _withDirectives(_createVNode(_component_hint, { class: "hint" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTranslation("SettingsMaxAmountFilterHint")), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, !_ctx.hasError(_ctx.errorFieldEnum.MaxFilter)]
            ]),
            _withDirectives(_createVNode(_component_hint, { class: "hint-error" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTranslation("SettingsMaxAmountFilterHintError")), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, _ctx.hasError(_ctx.errorFieldEnum.MaxFilter)]
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_46, [
        _createElementVNode("div", _hoisted_47, [
          _createVNode(_component_r_button, {
            variant: "primary",
            onclick: _ctx.handleSubmit,
            disabled: _ctx.changedFields.length == 0 && !_ctx.elementUpdated
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTranslation("SettingsSave")), 1)
            ]),
            _: 1
          }, 8, ["onclick", "disabled"])
        ]),
        _createElementVNode("div", _hoisted_48, [
          _createVNode(_component_r_button, {
            variant: "text",
            onclick: _ctx.handleClose
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTranslation("SettingsClose")), 1)
            ]),
            _: 1
          }, 8, ["onclick"])
        ])
      ])
    ])
  ]))
}