import { Telemetry } from "./utils/telemetry";
import { defineCustomElements } from "@riverty/web-components/loader";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { vMaska } from "maska";

const app = createApp(App);
app.use(router);
defineCustomElements(window);
app.directive("maska", vMaska);

Telemetry.init();

app.mount("#app");
